// // src/pages/product-details/index.js
// import React, { useEffect } from 'react';
// import SEO from '../../components/SEO';
// import { SchemaBuilder } from '../../components/SEO/SchemaBuilder';
// import { SEOUtils } from '../../components/SEO/utils';
// import Header from './Header';
// import TechnologiesSection from './TechnologiesSection';
// import TechnicalInsights from './TechnicalInsights';
// import CustomizationSection from './CustomizationSection';

// const ProductDetails = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   // Create breadcrumb data
//   const breadcrumbs = SEOUtils.generateBreadcrumbs('/product/details');
//   const breadcrumbSchema = SchemaBuilder.getBreadcrumbSchema(breadcrumbs);

//   // Detailed product schema with technical specifications
//   const productSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Product',
//     'name': 'ExoSuit VR',
//     'brand': {
//       '@type': 'Brand',
//       'name': 'Newton Technologies'
//     },
//     'description': 'Advanced VR exoskeleton featuring patented dual-piston locomotion system and full-body force feedback technology.',
//     'category': 'Virtual Reality Equipment',
//     'productID': 'ESVR-2024',
//     'releaseDate': '2024',
//     'manufacturer': {
//       '@type': 'Organization',
//       'name': 'Newton Technologies'
//     },
//     'additionalProperty': [
//       {
//         '@type': 'PropertyValue',
//         'name': 'Force Feedback System',
//         'value': 'Dual-Piston Technology'
//       },
//       {
//         '@type': 'PropertyValue',
//         'name': 'Haptic Resolution',
//         'value': 'High-fidelity force feedback'
//       },
//       {
//         '@type': 'PropertyValue',
//         'name': 'Motion Tracking',
//         'value': 'Full-body tracking system'
//       }
//     ]
//   };

//   // Technical specification schema
//   const technicalSpecsSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'TechnicalSpecification',
//     'name': 'ExoSuit VR Technical Specifications',
//     'category': 'VR Hardware',
//     'specifications': [
//       {
//         '@type': 'PropertyValue',
//         'name': 'Locomotion System',
//         'value': 'Patented Dual-Piston System'
//       },
//       {
//         '@type': 'PropertyValue',
//         'name': 'Force Feedback',
//         'value': 'Full-body haptic system'
//       },
//       {
//         '@type': 'PropertyValue',
//         'name': 'AI Integration',
//         'value': 'Real-time adaptive response'
//       }
//     ]
//   };

//   // Application schema for different use cases
//   const applicationSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'ItemList',
//     'name': 'ExoSuit VR Applications',
//     'itemListElement': [
//       {
//         '@type': 'ListItem',
//         'position': 1,
//         'name': 'Military Training',
//         'description': 'Advanced military simulation and training applications'
//       },
//       {
//         '@type': 'ListItem',
//         'position': 2,
//         'name': 'Industrial Manufacturing',
//         'description': 'Virtual prototyping and assembly training'
//       },
//       {
//         '@type': 'ListItem',
//         'position': 3,
//         'name': 'Medical Training',
//         'description': 'Surgical simulation and medical procedure training'
//       }
//     ]
//   };

//   return (
//     <div className="bg-gradient-to-b from-[#1d2027] to-[#2a3540] text-white">
//       <SEO 
//         title="ExoSuit VR Technical Specifications and Features"
//         description="Dive deep into ExoSuit VR's groundbreaking features, including our patented dual-piston system, full-body force feedback, and advanced AI integration. Discover how our technology transforms virtual interaction."
//         keywords="ExoSuit VR specifications, VR force feedback technology, dual-piston system, haptic feedback specs, VR exoskeleton features"
//         image="/images/exosuit-technical.jpg"
//         url="/product/details"
//         ogType="product"
//         structuredData={[
//           productSchema,
//           technicalSpecsSchema,
//           applicationSchema,
//           breadcrumbSchema
//         ]}
//       >
//         {/* Additional meta tags for technical details */}
//         <meta name="technical:specification:version" content="1.0" />
//         <meta name="product:technology" content="Force Feedback" />
//         <meta name="application:category" content="Virtual Reality" />
//       </SEO>

//       <div className="container mx-auto px-6 py-24 max-w-7xl">
//         <Header />
//         <TechnologiesSection />
//         <TechnicalInsights />
//         <CustomizationSection />
//       </div>
//     </div>
//   );
// };

// export default ProductDetails;

// src/pages/product-details/index.js
import React, { useEffect } from 'react';
import SEO from '../../components/SEO';
import { SchemaBuilder } from '../../components/SEO/SchemaBuilder';
import { SEOUtils } from '../../components/SEO/utils';
import Header from './Header';
import TechnologiesSection from './TechnologiesSection';
import TechnicalInsights from './TechnicalInsights';
import CustomizationSection from './CustomizationSection';

const ProductDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const breadcrumbs = SEOUtils.generateBreadcrumbs('/product/details');
  const breadcrumbSchema = SchemaBuilder.getBreadcrumbSchema(breadcrumbs);

  const productSchema = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: 'ExoSuit VR',
    image: 'https://newtonxr.com/images/exosuit-technical.jpg',
    brand: {
      '@type': 'Brand',
      name: 'Newton Technologies'
    },
    description: 'Advanced VR exoskeleton featuring patented dual-piston locomotion system and full-body force feedback technology.',
    category: 'Virtual Reality Equipment',
    productID: 'ESVR-2024',
    releaseDate: '2024',
    manufacturer: {
      '@type': 'Organization',
      name: 'Newton Technologies',
      url: 'https://newtonxr.com'
    },
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/PreOrder',
      url: 'https://newtonxr.com/product/details',
      priceCurrency: 'USD',
      price: '0',
      priceValidUntil: '2025-12-31',
      itemCondition: 'https://schema.org/NewCondition',
      seller: {
        '@type': 'Organization',
        name: 'Newton Technologies'
      }
    },
    additionalProperty: [
      {
        '@type': 'PropertyValue',
        name: 'Force Feedback System',
        value: 'Dual-Piston Technology'
      },
      {
        '@type': 'PropertyValue',
        name: 'Haptic Resolution',
        value: 'High-fidelity force feedback'
      },
      {
        '@type': 'PropertyValue',
        name: 'Motion Tracking',
        value: 'Full-body tracking system'
      }
    ]
  };

  const technicalSpecsSchema = {
    '@context': 'https://schema.org',
    '@type': 'TechnicalSpecification',
    name: 'ExoSuit VR Technical Specifications',
    category: 'VR Hardware',
    specifications: [
      {
        '@type': 'PropertyValue',
        name: 'Locomotion System',
        value: 'Patented Dual-Piston System'
      },
      {
        '@type': 'PropertyValue',
        name: 'Force Feedback',
        value: 'Full-body haptic system'
      },
      {
        '@type': 'PropertyValue',
        name: 'AI Integration',
        value: 'Real-time adaptive response'
      }
    ]
  };

  const applicationSchema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    name: 'ExoSuit VR Applications',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Military Training',
        description: 'Advanced military simulation and training applications'
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Industrial Manufacturing',
        description: 'Virtual prototyping and assembly training'
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: 'Medical Training',
        description: 'Surgical simulation and medical procedure training'
      }
    ]
  };

  return (
    <div className="bg-gradient-to-b from-[#1d2027] to-[#2a3540] text-white">
      <SEO 
        title="ExoSuit VR Specifications | Force Feedback Technology"
        description="Explore ExoSuit VR's innovative features: dual-piston system and full-body force feedback. Learn how our VR technology enhances training and simulation."
        url="/product/details"
        ogType="product"
        image="/images/exosuit-technical.jpg"
        structuredData={[
          productSchema,
          technicalSpecsSchema,
          applicationSchema,
          breadcrumbSchema
        ]}
      >
        {/* Product Properties */}
        <meta property="product:condition" content="new" />
        <meta property="product:availability" content="preorder" />
        <meta property="product:price:amount" content="0" />
        <meta property="product:price:currency" content="USD" />
        
        {/* Technical Categories */}
        <meta property="article:section" content="Technical Specifications" />
        <meta property="article:tag" content="Force Feedback" />
        <meta property="article:tag" content="VR Technology" />
        <meta property="article:tag" content="Haptic Feedback" />
        
        {/* Applications */}
        <meta property="article:section" content="Military Training" />
        <meta property="article:section" content="Medical Simulation" />
        <meta property="article:section" content="Industrial Applications" />
      </SEO>

      <div className="container mx-auto px-6 py-24 max-w-7xl">
        <Header />
        <TechnologiesSection />
        <TechnicalInsights />
        <CustomizationSection />
      </div>
    </div>
  );
};

export default ProductDetails;