// // src/pages/team/index.js
// import React, { useState, useEffect } from 'react';
// import SEO from '../../components/SEO';
// import TeamHeader from './TeamHeader';
// import TeamGrid from './TeamGrid';
// import TeamMemberModal from './TeamMemberModal';
// import { teamMembers } from './teamData';

// const Team = () => {
//   const [selectedMember, setSelectedMember] = useState(null);
  
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   // Enhanced Team Leadership Schema
//   const leadershipSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Organization',
//     name: 'Newton Technologies Leadership',
//     description: 'Meet the innovative team behind Newton Technologies, pioneers in VR force feedback technology. Our diverse expertise spans VR development, software engineering, and mechanical design, driving the future of virtual reality interaction.',
//     founder: {
//       '@type': 'Person',
//       name: 'David Stahl',
//       jobTitle: 'CEO & Founder',
//       description: 'Pioneering force feedback technology in VR since 2013'
//     },
//     member: [
//       {
//         '@type': 'Person',
//         name: 'David Stahl',
//         jobTitle: 'CEO & Founder',
//         image: '/images/david.jpg',
//         description: 'Visionary founder with expertise in Startup Development and Machine Learning, leading Newton\'s innovation in VR force feedback technology'
//       },
//       {
//         '@type': 'Person',
//         name: 'Bruno Nigri',
//         jobTitle: 'CTO',
//         image: '/images/bruno-nigri.jpg',
//         description: 'Software engineering expert specializing in C/C++ and embedded systems, driving Newton\'s technical innovation'
//       },
//       {
//         '@type': 'Person',
//         name: 'Lucas Triginelli',
//         jobTitle: 'Chief Product Development Officer',
//         image: '/images/lucas.jpg',
//         description: 'Mechanical design and product development specialist, transforming VR concepts into reality'
//       }
//     ]
//   };

//   return (
//     <div className="container mx-auto px-6 py-24">
//       <SEO 
//         title="Newton Technologies Team | VR Innovation Leaders"
//         description="Meet the experts driving VR innovation at Newton Technologies. Our team develops revolutionary force feedback systems for virtual reality."
//         url="/team"
//         ogType="profile"
//         structuredData={leadershipSchema}
//         canonical="https://newtonxr.com/team"
//       >
//         {/* Core meta tag */}
//         <meta name="robots" content="index, follow" />
        
//         {/* Team Members Meta Tags */}
//         <meta property="article:author" content="David Stahl" />
//         <meta name="team:ceo" content="David Stahl - CEO & Founder" />
//         <meta name="team:cto" content="Bruno Nigri - Chief Technology Officer" />
//         <meta name="team:cpdo" content="Lucas Triginelli - Chief Product Development Officer" />
        
//         {/* Team Description */}
//         <meta name="team:overview" content="Leaders in VR force feedback innovation, combining expertise in software engineering, mechanical design, and product development." />
//         <meta name="team:expertise" content="VR Technology, Software Engineering, Mechanical Design" />
//         <meta name="team:patents" content="Force Feedback VR Exoskeleton (2022)" />
//         <meta name="team:focus" content="Force Feedback Systems, VR Innovation, Hardware Development" />
        
//         {/* OpenGraph */}
//         <meta property="og:title" content="Newton Technologies Team | VR Innovation Leaders" />
//         <meta property="og:type" content="website" />
//         <meta property="og:url" content="https://newtonxr.com/team" />
//         <meta property="og:description" content="Meet the experts driving VR innovation at Newton Technologies. Our team develops revolutionary force feedback systems for virtual reality." />
        
//         {/* Twitter Card */}
//         <meta name="twitter:card" content="summary_large_image" />
//         <meta name="twitter:title" content="Newton Technologies Leadership Team" />
//         <meta name="twitter:description" content="David Stahl (CEO), Bruno Nigri (CTO), Lucas Triginelli (CPDO) - Leading VR innovation" />
        
//         {/* Resource optimization */}
//         <link rel="preload" href="/images/david.jpg" as="image" />
//         <link rel="preload" href="/images/bruno-nigri.jpg" as="image" />
//         <link rel="preload" href="/images/lucas.jpg" as="image" />
//       </SEO>

//       <TeamHeader />
//       <TeamGrid 
//         members={teamMembers} 
//         onMemberClick={setSelectedMember} 
//       />
//       <TeamMemberModal 
//         member={selectedMember} 
//         onClose={() => setSelectedMember(null)} 
//       />
//     </div>
//   );
// };

// export default Team;

// // src/pages/team/index.js
// import React, { useState, useEffect } from 'react';
// import SEO from '../../components/SEO';
// import TeamHeader from './TeamHeader';
// import TeamGrid from './TeamGrid';
// import TeamMemberModal from './TeamMemberModal';
// import { teamMembers } from './teamData';

// const Team = () => {
//   const [selectedMember, setSelectedMember] = useState(null);
  
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   // Founder and Leadership Schema
//   const founderSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Person',
//     name: 'David Stahl',
//     jobTitle: 'CEO & Founder',
//     image: 'https://newtonxr.com/images/david.jpg',
//     description: 'Pioneering force feedback technology in VR since 2013. Patent holder of VR exoskeleton technology.',
//     hasOccupation: {
//       '@type': 'Occupation',
//       name: 'Chief Executive Officer',
//       occupationLocation: {
//         '@type': 'Place',
//         address: {
//           '@type': 'PostalAddress',
//           addressLocality: 'Tel Aviv',
//           addressCountry: 'Israel'
//         }
//       }
//     }
//   };

//   const organizationSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Organization',
//     name: 'Newton Technologies',
//     description: 'Pioneering VR force feedback technology company',
//     founder: founderSchema,
//     foundingDate: '2023',
//     foundingLocation: {
//       '@type': 'Place',
//       name: 'Tel Aviv, Israel'
//     },
//     numberOfEmployees: 3,
//     employee: [
//       {
//         '@type': 'Person',
//         name: 'Bruno Nigri',
//         jobTitle: 'Chief Technology Officer',
//         image: 'https://newtonxr.com/images/bruno-nigri.jpg',
//         description: 'Software engineering expert leading technological innovation'
//       },
//       {
//         '@type': 'Person',
//         name: 'Lucas Triginelli',
//         jobTitle: 'Chief Product Development Officer',
//         image: 'https://newtonxr.com/images/lucas.jpg',
//         description: 'Mechanical design specialist transforming VR concepts into reality'
//       }
//     ]
//   };

//   return (
//     <div className="container mx-auto px-6 py-24">
//       <SEO 
//         title="Newton Technologies Team | VR Innovation Leaders"
//         description="Meet the experts driving VR innovation at Newton Technologies. Our team develops revolutionary force feedback systems for virtual reality."
//         url="/team"
//         ogType="profile"
//         structuredData={[founderSchema, organizationSchema]}
//         canonical="https://newtonxr.com/team"
//       >
//         {/* Core meta tags */}
//         <meta name="robots" content="index, follow" />
//         <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        
//         {/* Founding Team Meta Tags */}
//         <meta name="founder" content="David Stahl" />
//         <meta name="founder:role" content="CEO & Founder" />
//         <meta name="founder:innovation" content="VR Exoskeleton Patent (2022)" />
//         <meta name="founder:experience" content="Pioneering VR force feedback since 2013" />
        
//         {/* Leadership Team */}
//         <meta name="leadership:cto" content="Bruno Nigri - Software Engineering Expert" />
//         <meta name="leadership:cpdo" content="Lucas Triginelli - Mechanical Design Specialist" />
        
//         {/* Company Information */}
//         <meta name="company:founded" content="2023" />
//         <meta name="company:location" content="Tel Aviv, Israel" />
//         <meta name="company:size" content="3" />
//         <meta name="company:focus" content="VR Force Feedback Technology" />

//         {/* OpenGraph Tags */}
//         <meta property="og:title" content="Newton Technologies Team | VR Innovation Leaders" />
//         <meta property="og:type" content="profile" />
//         <meta property="og:url" content="https://newtonxr.com/team" />
//         <meta property="og:description" content="Meet our leaders: David Stahl (CEO & Founder), Bruno Nigri (CTO), Lucas Triginelli (CPDO)" />
//         <meta property="og:image" content="https://newtonxr.com/images/team/leadership-team.jpg" />
//         <meta property="og:founder" content="David Stahl" />
//         <meta property="og:founding_date" content="2023" />
//         <meta property="og:team_size" content="3" />
        
//         {/* Twitter Card */}
//         <meta name="twitter:card" content="summary_large_image" />
//         <meta name="twitter:title" content="Newton Technologies Leadership" />
//         <meta name="twitter:description" content="Founded by David Stahl in 2023, leading VR innovation with Bruno Nigri (CTO) and Lucas Triginelli (CPDO)" />
//         <meta name="twitter:image" content="https://newtonxr.com/images/team/leadership-team.jpg" />
        
//         {/* Images with proper attributes */}
//         <link 
//           rel="preload" 
//           href="/images/david.jpg" 
//           as="image"
//           fetchpriority="high"
//         />
//         <link 
//           rel="preload" 
//           href="/images/bruno-nigri.jpg" 
//           as="image"
//           fetchpriority="high"
//         />
//         <link 
//           rel="preload" 
//           href="/images/lucas.jpg" 
//           as="image"
//           fetchpriority="high"
//         />
//       </SEO>

//       <TeamHeader />
//       <TeamGrid 
//         members={teamMembers} 
//         onMemberClick={setSelectedMember} 
//       />
//       <TeamMemberModal 
//         member={selectedMember} 
//         onClose={() => setSelectedMember(null)} 
//       />
//     </div>
//   );
// };

// export default Team;



// // src/pages/team/index.js
// import React, { useState, useEffect } from 'react';
// import SEO from '../../components/SEO';
// import TeamHeader from './TeamHeader';
// import TeamGrid from './TeamGrid';
// import TeamMemberModal from './TeamMemberModal';
// import { teamMembers } from './teamData';

// const Team = () => {
//   const [selectedMember, setSelectedMember] = useState(null);
  
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const davidStahlSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Person',
//     name: 'David Stahl',
//     jobTitle: 'CEO & Founder',
//     image: 'https://newtonxr.com/images/david.jpg',
//     description: 'Pioneering force feedback technology in VR since 2013. Patent holder of VR exoskeleton technology.',
//     sameAs: [
//       'https://linkedin.com/in/david-stahl-vr',
//       'https://twitter.com/davidstahlvr'
//     ],
//     alumniOf: {
//       '@type': 'CollegeOrUniversity',
//       name: 'Hebrew University of Jerusalem'
//     },
//     hasOccupation: {
//       '@type': 'Occupation',
//       name: 'Chief Executive Officer',
//       skills: ['VR Technology', 'Force Feedback Systems', 'Business Development'],
//       description: 'Founded Newton Technologies and invented patented VR exoskeleton technology',
//       occupationLocation: {
//         '@type': 'Place',
//         address: {
//           '@type': 'PostalAddress',
//           addressLocality: 'Tel Aviv',
//           addressCountry: 'Israel'
//         }
//       }
//     },
//     knowsAbout: ['Virtual Reality', 'Force Feedback Technology', 'Exoskeletons', 'Startup Development']
//   };

//   const brunoNigriSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Person',
//     name: 'Bruno Nigri',
//     jobTitle: 'Chief Technology Officer',
//     image: 'https://newtonxr.com/images/bruno-nigri.jpg',
//     description: 'Software engineering expert specializing in embedded systems and real-time environments',
//     sameAs: ['https://linkedin.com/in/bruno-nigri'],
//     hasOccupation: {
//       '@type': 'Occupation',
//       name: 'Chief Technology Officer',
//       skills: ['C/C++', 'Embedded Systems', 'Real-time Systems', 'Software Architecture'],
//       description: 'Leads technological development of VR force feedback systems'
//     },
//     knowsAbout: ['Software Engineering', 'Embedded Systems', 'Mechatronics', 'VR Technology']
//   };

//   const lucasTriginelliSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Person',
//     name: 'Lucas Triginelli',
//     jobTitle: 'Chief Product Development Officer',
//     image: 'https://newtonxr.com/images/lucas.jpg',
//     description: 'Mechanical design specialist with expertise in product development and testing',
//     sameAs: ['https://linkedin.com/in/lucas-triginelli'],
//     hasOccupation: {
//       '@type': 'Occupation',
//       name: 'Chief Product Development Officer',
//       skills: ['Mechanical Design', 'Product Development', 'Prototyping', 'Testing'],
//       description: 'Leads product development of VR exoskeleton systems'
//     },
//     knowsAbout: ['Mechanical Engineering', 'Product Design', 'VR Hardware', 'Exoskeleton Systems']
//   };

//   const organizationSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Organization',
//     name: 'Newton Technologies',
//     url: 'https://newtonxr.com',
//     logo: 'https://newtonxr.com/images/logo.png',
//     description: 'Pioneering VR force feedback technology company developing revolutionary exoskeleton systems',
//     founder: davidStahlSchema,
//     foundingDate: '2023',
//     foundingLocation: {
//       '@type': 'Place',
//       name: 'Tel Aviv, Israel'
//     },
//     numberOfEmployees: 3,
//     employee: [brunoNigriSchema, lucasTriginelliSchema],
//     knowsAbout: ['Virtual Reality', 'Force Feedback Technology', 'Exoskeletons', 'VR Training'],
//     award: {
//       '@type': 'Award',
//       name: 'Patent Grant',
//       description: 'VR Exoskeleton Technology Patent (2022)'
//     }
//   };

//   return (
//     <div className="container mx-auto px-6 py-24">
//       <SEO 
//         title="Newton Technologies Team | VR Innovation Leaders"
//         description="Meet Newton's VR innovation team: David Stahl (CEO), Bruno Nigri (CTO), and Lucas Triginelli (CPDO). Leading force feedback technology development."
//         url="/team"
//         ogType="profile"
//         structuredData={[
//           davidStahlSchema,
//           brunoNigriSchema,
//           lucasTriginelliSchema,
//           organizationSchema
//         ]}
//         canonical="https://newtonxr.com/team"
//       >
//         <meta name="robots" content="index, follow, max-image-preview:large" />
        
//         <meta property="article:author" content="David Stahl" />
//         <meta property="article:tag" content="VR Exoskeleton" />
//         <meta property="article:tag" content="Force Feedback" />
        
//         <meta property="profile:first_name" content="David" />
//         <meta property="profile:last_name" content="Stahl" />
        
//         <meta property="og:title" content="Newton Technologies Team | VR Innovation Leaders" />
//         <meta property="og:type" content="profile" />
//         <meta property="og:description" content="Meet our VR innovation team: David Stahl, Bruno Nigri, and Lucas Triginelli. Pioneers in force feedback technology." />
//         <meta property="article:published_time" content="2023" />
//         <meta property="article:modified_time" content="2024" />
//       </SEO>

//       <TeamHeader />
//       <TeamGrid 
//         members={teamMembers} 
//         onMemberClick={setSelectedMember} 
//       />
//       <TeamMemberModal 
//         member={selectedMember} 
//         onClose={() => setSelectedMember(null)} 
//       />
//     </div>
//   );
// };

// export default Team;

// src/pages/team/index.js
import React, { useState, useEffect } from 'react';
import SEO from '../../components/SEO';
import TeamHeader from './TeamHeader';
import TeamGrid from './TeamGrid';
import TeamMemberModal from './TeamMemberModal';
import { teamMembers } from './teamData';

const Team = () => {
  const [selectedMember, setSelectedMember] = useState(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const davidStahlSchema = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: 'David Stahl',
    jobTitle: 'CEO & Founder',
    image: 'https://newtonxr.com/images/david.jpg',
    description: 'VR innovator since 2013, patent holder of VR exoskeleton technology.',
    sameAs: [
      'https://linkedin.com/in/david-stahl-vr',
      'https://twitter.com/davidstahlvr'
    ],
    alumniOf: {
      '@type': 'CollegeOrUniversity',
      name: 'Hebrew University of Jerusalem'
    },
    hasOccupation: {
      '@type': 'Occupation',
      name: 'Chief Executive Officer',
      skills: ['VR Technology', 'Force Feedback Systems', 'Business Development'],
      description: 'Founded Newton Technologies and invented patented VR exoskeleton technology',
      occupationLocation: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Tel Aviv',
          addressCountry: 'Israel'
        }
      }
    },
    awards: [{
      '@type': 'Award',
      name: 'Patent Grant',
      description: 'VR Exoskeleton Technology Patent (2022)'
    }],
    knowsAbout: ['Virtual Reality', 'Force Feedback Technology', 'Exoskeletons', 'Startup Development']
  };

  const brunoNigriSchema = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: 'Bruno Nigri',
    jobTitle: 'Chief Technology Officer',
    image: 'https://newtonxr.com/images/bruno-nigri.jpg',
    description: 'Software engineering expert in embedded systems and real-time VR applications.',
    sameAs: ['https://linkedin.com/in/bruno-nigri'],
    hasOccupation: {
      '@type': 'Occupation',
      name: 'Chief Technology Officer',
      skills: ['C/C++', 'Embedded Systems', 'Real-time Systems', 'Software Architecture'],
      description: 'Leads technological development of VR force feedback systems'
    },
    knowsAbout: ['Software Engineering', 'Embedded Systems', 'Mechatronics', 'VR Technology']
  };

  const lucasTriginelliSchema = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: 'Lucas Triginelli',
    jobTitle: 'Chief Product Development Officer',
    image: 'https://newtonxr.com/images/lucas.jpg',
    description: 'Mechanical design specialist and product development expert.',
    sameAs: ['https://linkedin.com/in/lucas-triginelli'],
    hasOccupation: {
      '@type': 'Occupation',
      name: 'Chief Product Development Officer',
      skills: ['Mechanical Design', 'Product Development', 'Prototyping', 'Testing'],
      description: 'Leads product development of VR exoskeleton systems'
    },
    knowsAbout: ['Mechanical Engineering', 'Product Design', 'VR Hardware', 'Exoskeleton Systems']
  };

  const teamSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Newton Technologies',
    url: 'https://newtonxr.com',
    logo: 'https://newtonxr.com/images/logo.png',
    description: 'Leading VR force feedback technology company pioneering exoskeleton systems',
    founder: davidStahlSchema,
    foundingDate: '2023',
    location: {
      '@type': 'Place',
      name: 'Tel Aviv, Israel'
    },
    numberOfEmployees: 3,
    employees: [brunoNigriSchema, lucasTriginelliSchema],
    knowsAbout: ['Virtual Reality', 'Force Feedback Technology', 'Exoskeletons', 'VR Training']
  };

  return (
    <div className="container mx-auto px-6 py-24">
      <SEO 
        title="Newton Technologies Leadership | David Stahl, Bruno Nigri, Lucas Triginelli"
        description="Meet Newton's innovative team: David Stahl (CEO), Bruno Nigri (CTO), and Lucas Triginelli (CPDO). Leaders in VR force feedback technology."
        url="/team"
        ogType="profile"
        image="/images/team.jpg"
        structuredData={[
          davidStahlSchema,
          brunoNigriSchema,
          lucasTriginelliSchema,
          teamSchema
        ]}
      >
        {/* Team Meta Tags */}
        <meta property="article:section" content="Leadership" />
        <meta property="article:tag" content="VR Technology" />
        <meta property="article:tag" content="Force Feedback" />
        <meta property="article:tag" content="Startup Team" />
        
        {/* Published Information */}
        <meta property="article:published_time" content="2023" />
        <meta property="article:modified_time" content="2024" />
        
        {/* Team Members */}
        <meta property="profile:username" content="davidstahlvr" />
        <meta property="profile:role" content="CEO & Founder" />
      </SEO>

      <TeamHeader />
      <TeamGrid 
        members={teamMembers} 
        onMemberClick={setSelectedMember} 
      />
      <TeamMemberModal 
        member={selectedMember} 
        onClose={() => setSelectedMember(null)} 
      />
    </div>
  );
};

export default Team;