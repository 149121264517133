// src/pages/404/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Home } from 'lucide-react';
import { motion } from 'framer-motion';

const NotFound = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#1d2027] to-[#2a3540] px-4">
      <motion.div 
        className="max-w-2xl mx-auto text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="mb-8">
          <motion.h1 
            className="text-8xl font-chillax-bold text-[#E67E22] mb-6"
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            404
          </motion.h1>
          <h2 className="text-3xl font-chillax-bold text-white mb-4">
            Page Not Found
          </h2>
          <p className="text-lg text-[#f2f2f2] font-avenir mb-8">
            We couldn't find the page you're looking for. 
            It might have been moved or no longer exists.
          </p>
        </div>

        {/* Home Link */}
        <motion.div 
          className="mb-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <Link
            to="/"
            className="inline-flex items-center justify-center space-x-3 py-4 px-8 bg-[#2a3540] hover:bg-[#3a4550] rounded-lg transition-all duration-200 group border border-transparent hover:border-[#517888]"
          >
            <Home 
              className="text-[#E67E22] group-hover:scale-110 transition-transform duration-200" 
              size={24} 
            />
            <span className="text-white font-chillax-medium text-lg">
              Back to Homepage
            </span>
          </Link>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default NotFound;