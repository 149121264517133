// // src/pages/product/index.js
// import React, { useEffect } from 'react';
// import SEO from '../../components/SEO';
// import ProductHero from './ProductHero';
// import ProductOverview from './ProductOverview';
// import EngineeringSection from './EngineeringSection';
// import TransformingIndustries from './TransformingIndustries';
// import ProductCTA from './ProductCTA';

// const Product = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   // Product Schema with required offers
//   const productSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Product',
//     name: 'ExoSuit VR',
//     image: 'https://newtonxr.com/images/exosuit-vr-2024.jpg',
//     description: 'Revolutionary VR exoskeleton with advanced force feedback for immersive virtual reality experiences.',
//     brand: {
//       '@type': 'Brand',
//       name: 'Newton Technologies'
//     },
//     manufacturer: {
//       '@type': 'Organization',
//       name: 'Newton Technologies',
//       url: 'https://newtonxr.com'
//     },
//     model: 'ExoSuit VR 2024',
//     category: 'Virtual Reality Equipment',
//     offers: {
//       '@type': 'Offer',
//       availability: 'https://schema.org/PreOrder',
//       url: 'https://newtonxr.com/product',
//       priceCurrency: 'USD',
//       price: '0',
//       priceValidUntil: '2024-12-31',
//       itemCondition: 'https://schema.org/NewCondition',
//       seller: {
//         '@type': 'Organization',
//         name: 'Newton Technologies'
//       }
//     }
//   };

//   // Technical Specifications Schema
//   const technicalSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'TechnicalSpecification',
//     name: 'ExoSuit VR Technical Details',
//     specifications: [
//       {
//         '@type': 'PropertyValue',
//         name: 'Force Feedback',
//         value: 'Dual-Piston System'
//       },
//       {
//         '@type': 'PropertyValue',
//         name: 'Response Time',
//         value: '<10ms'
//       },
//       {
//         '@type': 'PropertyValue',
//         name: 'Tracking',
//         value: '6-DOF Full Body'
//       }
//     ]
//   };

//   // Applications Schema
//   const applicationsSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'ItemList',
//     itemListElement: [
//       {
//         '@type': 'ListItem',
//         position: 1,
//         name: 'Military Training',
//         description: 'Advanced combat simulation with force feedback'
//       },
//       {
//         '@type': 'ListItem',
//         position: 2,
//         name: 'Medical Simulation',
//         description: 'Surgical and medical procedure training'
//       },
//       {
//         '@type': 'ListItem',
//         position: 3,
//         name: 'Industrial Applications',
//         description: 'Virtual prototyping and training'
//       }
//     ]
//   };

//   return (
//     <div className="bg-gradient-to-b from-[#1d2027] to-[#2a3540] min-h-screen text-white">
//       <SEO 
//         title="ExoSuit VR - Force Feedback System | Newton"
//         description="Experience breakthrough VR interaction with ExoSuit VR's dual-piston technology. Transform training and simulation through immersive force feedback."
//         url="/product"
//         ogType="product"
//         structuredData={[productSchema, technicalSchema, applicationsSchema]}
//         canonical="https://newtonxr.com/product"
//       >
//         {/* Product meta tags */}
//         <meta name="product:category" content="VR Hardware" />
//         <meta name="product:type" content="Force Feedback Exoskeleton" />
//         <meta name="product:availability" content="pre-order" />
        
//         {/* Technical specifications */}
//         <meta name="technical:feedback" content="Dual-Piston System" />
//         <meta name="technical:latency" content="<10ms" />
//         <meta name="technical:tracking" content="6-DOF" />
        
//         {/* OpenGraph Product Tags */}
//         <meta property="og:type" content="product" />
//         <meta property="product:availability" content="preorder" />
//         <meta property="product:condition" content="new" />
//         <meta property="product:price:amount" content="0" />
//         <meta property="product:price:currency" content="USD" />
        
//         {/* Rich media optimization */}
//         <link 
//           rel="preload" 
//           href="/images/exosuit-vr-2024.jpg" 
//           as="image"
//           fetchpriority="high"
//         />
//         <link 
//           rel="preload" 
//           href="/videos/exosuit-demo.mp4" 
//           as="video"
//         />
//       </SEO>

//       <div className="container mx-auto px-4 py-24 max-w-7xl">
//         <ProductHero />
//         <ProductOverview />
//         <div className="w-full h-px bg-gradient-to-r from-transparent via-[#E67E22] to-transparent my-24"></div>
//         <EngineeringSection />
//         <TransformingIndustries />
//         <ProductCTA />
//       </div>
//     </div>
//   );
// };

// export default Product;


// // src/pages/product/index.js
// import React, { useEffect } from 'react';
// import SEO from '../../components/SEO';
// import ProductHero from './ProductHero';
// import ProductOverview from './ProductOverview';
// import EngineeringSection from './EngineeringSection';
// import TransformingIndustries from './TransformingIndustries';
// import ProductCTA from './ProductCTA';

// const Product = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const productSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Product',
//     name: 'ExoSuit VR',
//     image: 'https://newtonxr.com/images/exosuit-vr-2024.jpg',
//     description: 'Revolutionary VR exoskeleton with advanced force feedback for immersive virtual reality experiences.',
//     brand: {
//       '@type': 'Brand',
//       name: 'Newton Technologies'
//     },
//     manufacturer: {
//       '@type': 'Organization',
//       name: 'Newton Technologies',
//       url: 'https://newtonxr.com'
//     },
//     model: 'ExoSuit VR 2024',
//     category: 'Virtual Reality Equipment',
//     offers: {
//       '@type': 'Offer',
//       availability: 'https://schema.org/PreOrder',
//       url: 'https://newtonxr.com/product',
//       priceCurrency: 'USD',
//       price: '0',
//       priceValidUntil: '2025-12-31',
//       itemCondition: 'https://schema.org/NewCondition',
//       seller: {
//         '@type': 'Organization',
//         name: 'Newton Technologies'
//       }
//     }
//   };

//   const technicalSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'TechnicalSpecification',
//     name: 'ExoSuit VR Technical Details',
//     specifications: [
//       {
//         '@type': 'PropertyValue',
//         name: 'Force Feedback',
//         value: 'Dual-Piston System'
//       },
//       {
//         '@type': 'PropertyValue',
//         name: 'Response Time',
//         value: '<10ms'
//       },
//       {
//         '@type': 'PropertyValue',
//         name: 'Tracking',
//         value: '6-DOF Full Body'
//       }
//     ]
//   };

//   const applicationsSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'ItemList',
//     itemListElement: [
//       {
//         '@type': 'ListItem',
//         position: 1,
//         name: 'Military Training',
//         description: 'Advanced combat simulation with force feedback'
//       },
//       {
//         '@type': 'ListItem',
//         position: 2,
//         name: 'Medical Simulation',
//         description: 'Surgical and medical procedure training'
//       },
//       {
//         '@type': 'ListItem',
//         position: 3,
//         name: 'Industrial Applications',
//         description: 'Virtual prototyping and training'
//       }
//     ]
//   };

//   return (
//     <div className="bg-gradient-to-b from-[#1d2027] to-[#2a3540] min-h-screen text-white">
//       <SEO 
//         title="ExoSuit VR - Force Feedback System | Newton"
//         description="Experience breakthrough VR interaction with ExoSuit VR's dual-piston technology. Transform training and simulation through immersive force feedback."
//         url="/product"
//         ogType="product"
//         structuredData={[productSchema, technicalSchema, applicationsSchema]}
//         canonical="https://newtonxr.com/product"
//       >
//         <meta name="product:category" content="VR Hardware" />
//         <meta name="product:type" content="Force Feedback Exoskeleton" />
//         <meta name="product:availability" content="pre-order" />
        
//         <meta name="technical:feedback" content="Dual-Piston System" />
//         <meta name="technical:latency" content="<10ms" />
//         <meta name="technical:tracking" content="6-DOF" />
        
//         <meta property="og:type" content="product" />
//         <meta property="product:availability" content="preorder" />
//         <meta property="product:condition" content="new" />
//         <meta property="product:price:amount" content="0" />
//         <meta property="product:price:currency" content="USD" />
        

//       </SEO>

//       <div className="container mx-auto px-4 py-24 max-w-7xl">
//         <ProductHero />
//         <ProductOverview />
//         <div className="w-full h-px bg-gradient-to-r from-transparent via-[#E67E22] to-transparent my-24"></div>
//         <EngineeringSection />
//         <TransformingIndustries />
//         <ProductCTA />
//       </div>
//     </div>
//   );
// };

// export default Product;


// src/pages/product/index.js
import React, { useEffect } from 'react';
import SEO from '../../components/SEO';
import ProductHero from './ProductHero';
import ProductOverview from './ProductOverview';
import EngineeringSection from './EngineeringSection';
import TransformingIndustries from './TransformingIndustries';
import ProductCTA from './ProductCTA';

const Product = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productSchema = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: 'ExoSuit VR',
    image: 'https://newtonxr.com/images/exosuit-vr-2024.jpg',
    description: 'Revolutionary VR exoskeleton with advanced force feedback for immersive virtual reality experiences.',
    brand: {
      '@type': 'Brand',
      name: 'Newton Technologies'
    },
    manufacturer: {
      '@type': 'Organization',
      name: 'Newton Technologies',
      url: 'https://newtonxr.com'
    },
    model: 'ExoSuit VR 2024',
    category: 'Virtual Reality Equipment',
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/PreOrder',
      url: 'https://newtonxr.com/product',
      priceCurrency: 'USD',
      price: '0',
      priceValidUntil: '2025-12-31',
      itemCondition: 'https://schema.org/NewCondition',
      seller: {
        '@type': 'Organization',
        name: 'Newton Technologies'
      }
    }
  };

  const technicalSchema = {
    '@context': 'https://schema.org',
    '@type': 'TechnicalSpecification',
    name: 'ExoSuit VR Technical Details',
    specifications: [
      {
        '@type': 'PropertyValue',
        name: 'Force Feedback',
        value: 'Dual-Piston System'
      },
      {
        '@type': 'PropertyValue',
        name: 'Response Time',
        value: '<10ms'
      },
      {
        '@type': 'PropertyValue',
        name: 'Tracking',
        value: '6-DOF Full Body'
      }
    ]
  };

  const applicationsSchema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Military Training',
        description: 'Advanced combat simulation with force feedback'
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Medical Simulation',
        description: 'Surgical and medical procedure training'
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: 'Industrial Applications',
        description: 'Virtual prototyping and training'
      }
    ]
  };

  return (
    <div className="bg-gradient-to-b from-[#1d2027] to-[#2a3540] min-h-screen text-white">
      <SEO 
        title="ExoSuit VR | Advanced Force Feedback Technology"
        description="Experience revolutionary force feedback in VR with ExoSuit VR's dual-piston technology. Perfect for military, medical, and industrial training."
        url="/product"
        ogType="product"
        image="/images/exosuit-vr-2024.jpg"
        structuredData={[productSchema, technicalSchema, applicationsSchema]}
      >
        {/* Product Information */}
        <meta property="product:condition" content="new" />
        <meta property="product:availability" content="preorder" />
        <meta property="product:price:amount" content="0" />
        <meta property="product:price:currency" content="USD" />
        
        {/* Technical Specifications */}
        <meta property="og:product:specifications" content="Dual-Piston System, <10ms Response, 6-DOF Tracking" />
        
        {/* Application Areas */}
        <meta property="article:section" content="Military Training" />
        <meta property="article:section" content="Medical Simulation" />
        <meta property="article:section" content="Industrial Applications" />
        
        {/* Technology Tags */}
        <meta property="article:tag" content="VR Technology" />
        <meta property="article:tag" content="Force Feedback" />
        <meta property="article:tag" content="Haptic Technology" />
      </SEO>

      <div className="container mx-auto px-4 py-24 max-w-7xl">
        <ProductHero />
        <ProductOverview />
        <div className="w-full h-px bg-gradient-to-r from-transparent via-[#E67E22] to-transparent my-24"></div>
        <EngineeringSection />
        <TransformingIndustries />
        <ProductCTA />
      </div>
    </div>
  );
};

export default Product;