// // src/pages/exoskeleton-applications/index.js
// import React, { useEffect } from 'react';
// import SEO from '../../components/SEO';
// import { SchemaBuilder } from '../../components/SEO/SchemaBuilder';
// import { SEOUtils } from '../../components/SEO/utils';
// import Header from './Header';
// import ApplicationCard from './ApplicationCard';
// import FutureSection from './FutureSection';
// import FAQSection from './FAQSection';
// import CTASection from './CTASection';
// import { applications, faqData } from './applicationsData';

// const ExoskeletonApplications = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   // Create breadcrumb data
//   const breadcrumbs = SEOUtils.generateBreadcrumbs('/exoskeleton-applications');
//   const breadcrumbSchema = SchemaBuilder.getBreadcrumbSchema(breadcrumbs);

//   // Create applications list schema
//   const applicationsSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'ItemList',
//     'itemListElement': applications.map((app, index) => ({
//       '@type': 'ListItem',
//       'position': index + 1,
//       'name': app.title,
//       'description': app.description,
//       'item': {
//         '@type': 'Thing',
//         'name': app.title,
//         'description': app.description,
//         'category': 'VR Applications'
//       }
//     }))
//   };

//   // FAQ Schema
//   const faqSchema = SchemaBuilder.getFAQSchema(faqData);

//   // Industry solutions schema
//   const industrySchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Service',
//     'name': 'ExoSuit VR Industry Solutions',
//     'provider': {
//       '@type': 'Organization',
//       'name': 'Newton Technologies'
//     },
//     'serviceType': 'Virtual Reality Solutions',
//     'hasOfferCatalog': {
//       '@type': 'OfferCatalog',
//       'name': 'Industry Applications',
//       'itemListElement': applications.map(app => ({
//         '@type': 'Offer',
//         'itemOffered': {
//           '@type': 'Service',
//           'name': app.title,
//           'description': app.description
//         }
//       }))
//     }
//   };

//   return (
//     <div className="container mx-auto px-6 py-24 max-w-7xl">
//       <SEO 
//         title="ExoSuit VR Applications - Industry Solutions"
//         description="Discover how ExoSuit VR's force feedback technology transforms multiple industries. From military training to medical simulation, explore our comprehensive VR solutions."
//         keywords="VR applications, military training VR, industrial VR, medical simulation, VR gaming, virtual reality training, ExoSuit VR solutions"
//         image="/images/applications-overview.jpg"
//         url="/exoskeleton-applications"
//         ogType="website"
//         structuredData={[
//           applicationsSchema,
//           faqSchema,
//           industrySchema,
//           breadcrumbSchema
//         ]}
//       >
//         {/* Additional meta tags for applications page */}
//         <meta name="application:category" content="Virtual Reality Solutions" />
//         <meta name="application:industries" content="Military, Medical, Industrial, Gaming" />
//         <meta name="twitter:label1" content="Industries Served" />
//         <meta name="twitter:data1" content="8+" />
//       </SEO>

//       <Header />
      
//       {applications.map((app, index) => (
//         <ApplicationCard 
//           key={index} 
//           application={app} 
//           index={index} 
//         />
//       ))}

//       <FutureSection />
//       <FAQSection />
//       <CTASection />
//     </div>
//   );
// };

// export default ExoskeletonApplications;

// src/pages/exoskeleton-applications/index.js
import React, { useEffect } from 'react';
import SEO from '../../components/SEO';
import { SchemaBuilder } from '../../components/SEO/SchemaBuilder';
import { SEOUtils } from '../../components/SEO/utils';
import Header from './Header';
import ApplicationCard from './ApplicationCard';
import FutureSection from './FutureSection';
import FAQSection from './FAQSection';
import CTASection from './CTASection';
import { applications, faqData } from './applicationsData';

const ExoskeletonApplications = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const breadcrumbs = SEOUtils.generateBreadcrumbs('/exoskeleton-applications');
  const breadcrumbSchema = SchemaBuilder.getBreadcrumbSchema(breadcrumbs);

  const applicationsSchema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: applications.map((app, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'Product',
        name: app.title,
        description: app.description,
        applicationCategory: 'VR Solutions',
        category: app.title,
        offers: {
          '@type': 'Offer',
          availability: 'https://schema.org/PreOrder',
          price: '0',
          priceCurrency: 'USD'
        }
      }
    }))
  };

  const industrySchema = {
    '@context': 'https://schema.org',
    '@type': 'Service',
    name: 'ExoSuit VR Industry Solutions',
    provider: {
      '@type': 'Organization',
      name: 'Newton Technologies',
      url: 'https://newtonxr.com'
    },
    serviceType: 'Virtual Reality Solutions',
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Industry Applications',
      itemListElement: applications.map(app => ({
        '@type': 'Offer',
        itemOffered: {
          '@type': 'Service',
          name: app.title,
          description: app.description,
          provider: {
            '@type': 'Organization',
            name: 'Newton Technologies'
          }
        }
      }))
    },
    areaServed: {
      '@type': 'GeoCircle',
      geoMidpoint: {
        '@type': 'GeoCoordinates',
        latitude: '32.0853',
        longitude: '34.7818'
      },
      geoRadius: '40075000'
    }
  };

  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqData.map(faq => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer
      }
    }))
  };

  return (
    <div className="container mx-auto px-6 py-24 max-w-7xl">
      <SEO 
        title="ExoSuit VR Applications | Force Feedback Solutions"
        description="Transform your industry with ExoSuit VR's force feedback technology. Military training, medical simulation, and industrial solutions."
        url="/exoskeleton-applications"
        ogType="website"
        image="/images/applications-overview.jpg"
        structuredData={[
          applicationsSchema,
          industrySchema,
          faqSchema,
          breadcrumbSchema
        ]}
      >
        {/* Industry Categories */}
        <meta property="article:section" content="VR Solutions" />
        <meta property="article:tag" content="Military Training" />
        <meta property="article:tag" content="Medical Simulation" />
        <meta property="article:tag" content="Industrial Applications" />
        
        {/* Solution Types */}
        <meta property="product:category" content="VR Training" />
        <meta property="product:category" content="Force Feedback Systems" />
        <meta property="product:category" content="Simulation Technology" />
        
        {/* Business Information */}
        <meta property="business:contact_data:service_area" content="Worldwide" />
        <meta property="business:contact_data:industry" content="Virtual Reality" />
        
        {/* Social Tags */}
        <meta name="twitter:label1" content="Applications" />
        <meta name="twitter:data1" content="Military, Medical, Industrial" />
        <meta name="twitter:label2" content="Technology" />
        <meta name="twitter:data2" content="Force Feedback VR" />
      </SEO>

      <Header />
      {applications.map((app, index) => (
        <ApplicationCard 
          key={index} 
          application={app} 
          index={index} 
        />
      ))}
      <FutureSection />
      <FAQSection />
      <CTASection />
    </div>
  );
};

export default ExoskeletonApplications;