// src/components/SEO/utils.js

export const SEOUtils = {
    // Generate meta description from content
    generateMetaDescription(content, maxLength = 160) {
      if (!content) return '';
      
      const stripped = content.replace(/\s+/g, ' ').trim();
      if (stripped.length <= maxLength) return stripped;
      
      return stripped.substring(0, maxLength - 3) + '...';
    },
  
    // Generate canonical URL
    getCanonicalUrl(path) {
      const baseUrl = process.env.REACT_APP_SITE_URL || 'https://newtonxr.com';
      return `${baseUrl}${path}`;
    },
  
    // Clean and join keywords
    formatKeywords(keywords) {
      if (Array.isArray(keywords)) {
        return keywords
          .map(k => k.trim())
          .filter(k => k.length > 0)
          .join(', ');
      }
      return keywords;
    },
  
    // Generate breadcrumbs data
    generateBreadcrumbs(path) {
      const parts = path.split('/').filter(p => p);
      const breadcrumbs = [{ name: 'Home', url: '/' }];
      
      let currentPath = '';
      parts.forEach(part => {
        currentPath += `/${part}`;
        breadcrumbs.push({
          name: part.charAt(0).toUpperCase() + part.slice(1).replace(/-/g, ' '),
          url: currentPath
        });
      });
      
      return breadcrumbs;
    },
  
    // Generate social media meta tags
    getSocialMetaTags(data) {
      return {
        og: {
          title: data.title,
          description: data.description,
          image: data.image,
          url: data.url,
          type: data.type || 'website',
          siteName: data.siteName
        },
        twitter: {
          card: 'summary_large_image',
          title: data.title,
          description: data.description,
          image: data.image,
          creator: data.twitterHandle
        }
      };
    }
  };
  
  // Also export SchemaBuilder if needed
  export { SchemaBuilder } from './SchemaBuilder';
  
  // Default export
  export default SEOUtils;