// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import Layout from './components/Layout';

// Import pages
import Home from './pages/home';
import Product from './pages/product';
import Team from './pages/team';
import Vision from './pages/vision';
import ProductDetails from './pages/product-details';
import ExoskeletonApplications from './pages/exoskeleton-applications';
import ContactUs from './pages/ContactUs';
import NotFoundPage from './pages/404';

// Default SEO configuration
import { defaultSEO } from './components/SEO/DefaultSEO';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="theme-color" content="#1d2027" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="default" />
          <meta name="apple-mobile-web-app-title" content={defaultSEO.siteName} />
          <meta name="application-name" content={defaultSEO.siteName} />
          
          {/* Default OpenGraph */}
          <meta property="og:site_name" content={defaultSEO.siteName} />
          <meta property="og:locale" content={defaultSEO.language} />
          
          {/* Default Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={defaultSEO.twitterHandle} />
          
          {/* Default Robots */}
          <meta name="robots" content="index, follow" />
          <meta name="format-detection" content="telephone=no" />
          
          {/* PWA Tags */}
          <link rel="manifest" href="/manifest.json" />
          <link rel="icon" href="/favicon.ico" sizes="any" />
          <link rel="icon" href="/icon.svg" type="image/svg+xml" />
          <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
          
          {/* Default Schema */}
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: defaultSEO.organization.name,
              url: defaultSEO.organization.url,
              logo: defaultSEO.organization.logo,
              sameAs: defaultSEO.organization.sameAs,
              contactPoint: {
                '@type': 'ContactPoint',
                telephone: defaultSEO.contact.phone,
                email: defaultSEO.contact.email,
                contactType: 'customer service',
                areaServed: 'Worldwide',
                availableLanguage: ['English', 'Hebrew']
              }
            })}
          </script>
        </Helmet>

        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product" element={<Product />} />
            <Route path="/team" element={<Team />} />
            <Route path="/vision" element={<Vision />} />
            <Route path="/exoskeleton-applications" element={<ExoskeletonApplications />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/product/details" element={<ProductDetails />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Layout>
      </Router>
    </HelmetProvider>
  );
}

export default App;