// src/components/SEO/DefaultSEO.js
export const defaultSEO = {
  siteName: 'Newton Technologies',
  title: 'Newton Technologies | Revolutionary VR Force Feedback',
  description: 'Newton Technologies develops breakthrough force feedback exoskeletons for virtual reality, transforming how humans interact with digital worlds.',
  keywords: 'VR exoskeleton, force feedback, virtual reality, haptic technology',
  image: '/images/og-default.jpg',
  twitterHandle: '@newtonxr',
  language: 'en_US',
  organization: {
    name: 'Newton Technologies',
    url: 'https://newtonxr.com',
    logo: '/images/logo.png',
    sameAs: [
      'https://twitter.com/newtonxr',
      'https://linkedin.com/company/newton-technologies',
    ]
  },
  address: {
    streetAddress: '',
    addressLocality: 'Tel Aviv',
    addressCountry: 'Israel',
  },
  contact: {
    email: 'info@newtonxr.com',
    phone: '+972 559572906',
  }
};