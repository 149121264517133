// src/components/SEO/SchemaBuilder.js
import { defaultSEO } from './DefaultSEO';

export const SchemaBuilder = {
  // Organization schema
  getOrganizationSchema() {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: defaultSEO.organization.name,
      url: defaultSEO.organization.url,
      logo: defaultSEO.organization.logo,
      sameAs: defaultSEO.organization.sameAs,
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: defaultSEO.contact.phone,
        email: defaultSEO.contact.email,
        contactType: 'customer service',
        areaServed: 'Worldwide',
        availableLanguage: ['English', 'Hebrew']
      },
      address: {
        '@type': 'PostalAddress',
        addressLocality: defaultSEO.address.addressLocality,
        addressCountry: defaultSEO.address.addressCountry
      }
    };
  },

  // Product schema
  getProductSchema({
    name,
    description,
    image,
    features = [],
    applicationCategory = 'Virtual Reality Hardware'
  }) {
    return {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name,
      description,
      image,
      brand: {
        '@type': 'Brand',
        name: defaultSEO.organization.name
      },
      manufacturer: {
        '@type': 'Organization',
        name: defaultSEO.organization.name
      },
      category: 'Virtual Reality Equipment',
      applicationCategory,
      features
    };
  },

  // WebPage schema
  getWebPageSchema({
    title,
    description,
    url,
    type = 'WebPage'
  }) {
    return {
      '@context': 'https://schema.org',
      '@type': type,
      name: title,
      description,
      url: `${defaultSEO.organization.url}${url}`,
      publisher: {
        '@type': 'Organization',
        name: defaultSEO.organization.name,
        logo: {
          '@type': 'ImageObject',
          url: defaultSEO.organization.logo
        }
      }
    };
  },

  // FAQ schema
  getFAQSchema(questions) {
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: questions.map(q => ({
        '@type': 'Question',
        name: q.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: q.answer
        }
      }))
    };
  },

  // Contact Page schema
  getContactPageSchema() {
    return {
      '@context': 'https://schema.org',
      '@type': 'ContactPage',
      name: 'Contact Newton Technologies',
      description: 'Get in touch with Newton Technologies for inquiries about our VR exoskeleton technology.',
      mainEntity: {
        '@type': 'Organization',
        name: defaultSEO.organization.name,
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: defaultSEO.contact.phone,
          email: defaultSEO.contact.email,
          contactType: 'customer service'
        }
      }
    };
  },

  // Breadcrumb schema
  getBreadcrumbSchema(items) {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: items.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: item.name,
        item: `${defaultSEO.organization.url}${item.url}`
      }))
    };
  }
};