// // src/pages/home/index.js
// import React, { useEffect } from 'react';
// import SEO from '../../components/SEO';
// import { SchemaBuilder } from '../../components/SEO/SchemaBuilder';
// import HeroSection from './HeroSection';
// import IntroductionSection from './IntroductionSection';
// import ExplanationSection from './ExplanationSection';
// import InnovationsSection from './InnovationsSection';
// import CallToAction from './CallToAction';

// const Home = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   // Organization Schema
//   const organizationSchema = SchemaBuilder.getOrganizationSchema();

//   // Product Schema
//   const productSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Product',
//     name: 'ExoSuit VR',
//     description: 'Revolutionary VR exoskeleton featuring advanced force feedback and patented dual-piston technology for unparalleled virtual reality interaction.',
//     brand: {
//       '@type': 'Brand',
//       name: 'Newton Technologies'
//     },
//     category: 'Virtual Reality Hardware',
//     manufacturer: {
//       '@type': 'Organization',
//       name: 'Newton Technologies'
//     },
//     features: [
//       'Patented Dual-Piston Locomotion System',
//       'Full-Body Force Feedback',
//       'Advanced AI Integration',
//       'Military-Grade Construction',
//       'Real-time Movement Tracking',
//       'Cross-Platform Compatibility'
//     ]
//   };

//   // Innovation Schema
//   const technologySchema = {
//     '@context': 'https://schema.org',
//     '@type': 'TechnologyArticle',
//     headline: 'Breakthrough Force Feedback Technology for Virtual Reality',
//     description: 'Newton Technologies introduces revolutionary force feedback technology that transforms virtual reality interaction.',
//     keywords: [
//       'force feedback technology',
//       'VR innovation',
//       'haptic feedback',
//       'virtual reality advancement'
//     ],
//     industryIdentifiers: [
//       'Military Training',
//       'Medical Simulation',
//       'Industrial Applications'
//     ]
//   };

//   // Application Schema
//   const applicationSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'ItemList',
//     itemListElement: [
//       {
//         '@type': 'Thing',
//         name: 'Military Training',
//         description: 'Advanced military simulation with realistic force feedback'
//       },
//       {
//         '@type': 'Thing',
//         name: 'Medical Training',
//         description: 'Precise medical procedure simulation with haptic feedback'
//       },
//       {
//         '@type': 'Thing',
//         name: 'Industrial Applications',
//         description: 'Virtual prototyping and industrial training solutions'
//       }
//     ]
//   };

//   return (
//     <>
//       <SEO 
//         title="Newton Technologies | Revolutionary VR Force Feedback Exoskeletons"
//         description="Transform virtual reality with Newton's breakthrough force feedback exoskeleton. Experience unparalleled physical interaction in VR with our patented dual-piston technology. Leading innovation in military training, medical simulation, and industrial applications."
//         keywords="VR exoskeleton, force feedback technology, virtual reality innovation, haptic feedback system, military VR training, medical simulation, industrial VR, ExoSuit VR, dual-piston technology, physical VR interaction"
//         image="/images/newton-hero-2024.jpg"
//         url="/"
//         ogType="website"
//         structuredData={[
//           organizationSchema,
//           productSchema,
//           technologySchema,
//           applicationSchema
//         ]}
//       >
//         {/* Page-specific meta tags */}
//         <meta name="robots" content="index, follow, max-image-preview:large" />
        
//         {/* Innovation tags */}
//         <meta name="innovation:category" content="Virtual Reality" />
//         <meta name="innovation:technology" content="Force Feedback" />
//         <meta name="innovation:patent" content="Dual-Piston System" />
        
//         {/* Industry tags */}
//         <meta name="industry:sectors" content="Military, Medical, Industrial" />
//         <meta name="application:type" content="Training & Simulation" />
        
//         {/* Product tags */}
//         <meta name="product:technology" content="Force Feedback" />
//         <meta name="product:category" content="VR Hardware" />
//         <meta name="product:features" content="Haptic Feedback, Movement Tracking, AI Integration" />
        
//         {/* Company tags */}
//         <meta name="company:name" content="Newton Technologies" />
//         <meta name="company:founded" content="2023" />
//         <meta name="company:location" content="Tel Aviv, Israel" />
        
//         {/* Social engagement */}
//         <meta name="twitter:label1" value="Technology Type" />
//         <meta name="twitter:data1" value="Force Feedback VR" />
//         <meta name="twitter:label2" value="Applications" />
//         <meta name="twitter:data2" value="Military, Medical, Industrial" />

//         {/* Rich media */}
//         <link rel="preload" href="/images/newton-hero-2024.jpg" as="image" />
//         <link rel="preload" href="/videos/product-demo.mp4" as="video" />
//       </SEO>

//       <main className="overflow-hidden">
//         <HeroSection />
//         <IntroductionSection />
//         <ExplanationSection />
//         <InnovationsSection />
//         <CallToAction />
//       </main>
//     </>
//   );
// };

// export default Home;



// // src/pages/home/index.js
// import React, { useEffect } from 'react';
// import SEO from '../../components/SEO';
// import { SchemaBuilder } from '../../components/SEO/SchemaBuilder';
// import HeroSection from './HeroSection';
// import IntroductionSection from './IntroductionSection';
// import ExplanationSection from './ExplanationSection';
// import InnovationsSection from './InnovationsSection';
// import CallToAction from './CallToAction';

// const Home = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const organizationSchema = SchemaBuilder.getOrganizationSchema();

//   const productSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Product',
//     name: 'ExoSuit VR',
//     image: 'https://newtonxr.com/images/exosuit-vr-2024.jpg',
//     description: 'Revolutionary VR exoskeleton featuring advanced force feedback and patented dual-piston technology for unparalleled virtual reality interaction.',
//     brand: {
//       '@type': 'Brand',
//       name: 'Newton Technologies'
//     },
//     category: 'Virtual Reality Hardware',
//     manufacturer: {
//       '@type': 'Organization',
//       name: 'Newton Technologies'
//     },
//     offers: {
//       '@type': 'Offer',
//       availability: 'https://schema.org/PreOrder',
//       url: 'https://newtonxr.com/product',
//       priceCurrency: 'USD',
//       price: '0',
//       priceValidUntil: '2025-12-31',
//       itemCondition: 'https://schema.org/NewCondition',
//       seller: {
//         '@type': 'Organization',
//         name: 'Newton Technologies'
//       }
//     },
//     features: [
//       'Patented Dual-Piston Locomotion System',
//       'Full-Body Force Feedback',
//       'Advanced AI Integration',
//       'Military-Grade Construction',
//       'Real-time Movement Tracking',
//       'Cross-Platform Compatibility'
//     ]
//   };

//   const technologySchema = {
//     '@context': 'https://schema.org',
//     '@type': 'TechnologyArticle',
//     headline: 'Breakthrough Force Feedback Technology for Virtual Reality',
//     description: 'Newton Technologies introduces revolutionary force feedback technology that transforms virtual reality interaction.',
//     keywords: [
//       'force feedback technology',
//       'VR innovation',
//       'haptic feedback',
//       'virtual reality advancement'
//     ],
//     industryIdentifiers: [
//       'Military Training',
//       'Medical Simulation',
//       'Industrial Applications'
//     ]
//   };

//   const applicationSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'ItemList',
//     itemListElement: [
//       {
//         '@type': 'Thing',
//         name: 'Military Training',
//         description: 'Advanced military simulation with realistic force feedback'
//       },
//       {
//         '@type': 'Thing',
//         name: 'Medical Training',
//         description: 'Precise medical procedure simulation with haptic feedback'
//       },
//       {
//         '@type': 'Thing',
//         name: 'Industrial Applications',
//         description: 'Virtual prototyping and industrial training solutions'
//       }
//     ]
//   };

//   return (
//     <>
//       <SEO 
//         title="Newton Technologies | Revolutionary VR Force Feedback Exoskeletons"
//         description="Transform virtual reality with Newton's breakthrough force feedback exoskeleton. Experience unparalleled physical interaction in VR with our patented dual-piston technology. Leading innovation in military training, medical simulation, and industrial applications."
//         keywords="VR exoskeleton, force feedback technology, virtual reality innovation, haptic feedback system, military VR training, medical simulation, industrial VR, ExoSuit VR, dual-piston technology, physical VR interaction"
//         image="/images/newton-hero-2024.jpg"
//         url="/"
//         ogType="website"
//         structuredData={[
//           organizationSchema,
//           productSchema,
//           technologySchema,
//           applicationSchema
//         ]}
//       >
//         <meta name="robots" content="index, follow, max-image-preview:large" />
        
//         <meta name="innovation:category" content="Virtual Reality" />
//         <meta name="innovation:technology" content="Force Feedback" />
//         <meta name="innovation:patent" content="Dual-Piston System" />
        
//         <meta name="industry:sectors" content="Military, Medical, Industrial" />
//         <meta name="application:type" content="Training & Simulation" />
        
//         <meta name="product:technology" content="Force Feedback" />
//         <meta name="product:category" content="VR Hardware" />
//         <meta name="product:features" content="Haptic Feedback, Movement Tracking, AI Integration" />
        
//         <meta name="company:name" content="Newton Technologies" />
//         <meta name="company:founded" content="2023" />
//         <meta name="company:location" content="Tel Aviv, Israel" />
        
//         <meta name="twitter:label1" value="Technology Type" />
//         <meta name="twitter:data1" value="Force Feedback VR" />
//         <meta name="twitter:label2" value="Applications" />
//         <meta name="twitter:data2" value="Military, Medical, Industrial" />
//       </SEO>

//       <main className="overflow-hidden">
//         <HeroSection />
//         <IntroductionSection />
//         <ExplanationSection />
//         <InnovationsSection />
//         <CallToAction />
//       </main>
//     </>
//   );
// };

// export default Home;

// src/pages/home/index.js
import React, { useEffect } from 'react';
import SEO from '../../components/SEO';
import { SchemaBuilder } from '../../components/SEO/SchemaBuilder';
import HeroSection from './HeroSection';
import IntroductionSection from './IntroductionSection';
import ExplanationSection from './ExplanationSection';
import InnovationsSection from './InnovationsSection';
import CallToAction from './CallToAction';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const organizationSchema = SchemaBuilder.getOrganizationSchema();

  const productSchema = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: 'ExoSuit VR',
    image: 'https://newtonxr.com/images/exosuit-vr-2024.jpg',
    description: 'Revolutionary VR exoskeleton featuring advanced force feedback and patented dual-piston technology for unparalleled virtual reality interaction.',
    brand: {
      '@type': 'Brand',
      name: 'Newton Technologies'
    },
    category: 'Virtual Reality Hardware',
    manufacturer: {
      '@type': 'Organization',
      name: 'Newton Technologies'
    },
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/PreOrder',
      url: 'https://newtonxr.com/product',
      priceCurrency: 'USD',
      price: '0',
      priceValidUntil: '2025-12-31',
      itemCondition: 'https://schema.org/NewCondition',
      seller: {
        '@type': 'Organization',
        name: 'Newton Technologies'
      }
    }
  };

  const technologySchema = {
    '@context': 'https://schema.org',
    '@type': 'TechnologyArticle',
    headline: 'Breakthrough Force Feedback Technology for Virtual Reality',
    description: 'Newton Technologies introduces revolutionary force feedback technology that transforms virtual reality interaction.',
    keywords: [
      'force feedback technology',
      'VR innovation',
      'haptic feedback',
      'virtual reality advancement'
    ],
    industryIdentifiers: [
      'Military Training',
      'Medical Simulation',
      'Industrial Applications'
    ]
  };

  const applicationSchema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: [
      {
        '@type': 'Thing',
        name: 'Military Training',
        description: 'Advanced military simulation with realistic force feedback'
      },
      {
        '@type': 'Thing',
        name: 'Medical Training',
        description: 'Precise medical procedure simulation with haptic feedback'
      },
      {
        '@type': 'Thing',
        name: 'Industrial Applications',
        description: 'Virtual prototyping and industrial training solutions'
      }
    ]
  };

  return (
    <>
      <SEO 
        title="Newton Technologies | Leading VR Force Feedback Innovation"
        description="Transform virtual reality with Newton's advanced force feedback exoskeleton. Experience revolutionary physical VR interaction with patented technology."
        url="/"
        ogType="website"
        image="/images/newton-hero-2024.jpg"
        structuredData={[
          organizationSchema,
          productSchema,
          technologySchema,
          applicationSchema
        ]}
      >
        <meta name="robots" content="index, follow, max-image-preview:large" />
        
        {/* Industry and Technology Tags */}
        <meta property="article:tag" content="Virtual Reality" />
        <meta property="article:tag" content="Force Feedback" />
        <meta property="article:tag" content="Exoskeleton" />
        
        {/* Application Areas */}
        <meta property="article:section" content="Military Training" />
        <meta property="article:section" content="Medical Simulation" />
        <meta property="article:section" content="Industrial Applications" />
        
        {/* Company Information */}
        <meta property="business:contact_data:locality" content="Tel Aviv" />
        <meta property="business:contact_data:country_name" content="Israel" />
        <meta property="business:contact_data:website" content="https://newtonxr.com" />
        
        {/* Custom Twitter Labels */}
        <meta name="twitter:label1" value="Technology" />
        <meta name="twitter:data1" value="Force Feedback VR" />
        <meta name="twitter:label2" value="Applications" />
        <meta name="twitter:data2" value="Military, Medical, Industrial" />
      </SEO>

      <main className="overflow-hidden">
        <HeroSection />
        <IntroductionSection />
        <ExplanationSection />
        <InnovationsSection />
        <CallToAction />
      </main>
    </>
  );
};

export default Home;