// // src/components/SEO/index.js
// import React from 'react';
// import { Helmet } from 'react-helmet';
// import PropTypes from 'prop-types';
// import { defaultSEO } from './DefaultSEO';

// const SEO = ({ 
//   title,
//   description,

//   image,
//   url,
//   ogType = 'website',
//   structuredData = null,
//   noindex = false,
//   children
// }) => {
//   const siteUrl = process.env.REACT_APP_SITE_URL || 'https://newtonxr.com';
//   const pageTitle = title || defaultSEO.title;
//   const metaDescription = description || defaultSEO.description;
//   const metaImage = image || defaultSEO.image;

//   const canonicalUrl = `${siteUrl}${url || ''}`;

//   // Combine with default data while prioritizing page-specific data
//   const meta = [
//     {
//       name: 'description',
//       content: metaDescription,
//     },
//     {
//       property: 'og:title',
//       content: pageTitle,
//     },
//     {
//       property: 'og:description',
//       content: metaDescription,
//     },
//     {
//       property: 'og:type',
//       content: ogType,
//     },
//     {
//       property: 'og:url',
//       content: canonicalUrl,
//     },
//     {
//       property: 'og:image',
//       content: metaImage,
//     },
//     {
//       property: 'og:site_name',
//       content: defaultSEO.siteName,
//     },
//     {
//       name: 'twitter:card',
//       content: 'summary_large_image',
//     },
//     {
//       name: 'twitter:creator',
//       content: defaultSEO.twitterHandle,
//     },
//     {
//       name: 'twitter:title',
//       content: pageTitle,
//     },
//     {
//       name: 'twitter:description',
//       content: metaDescription,
//     },
//     {
//       name: 'twitter:image',
//       content: metaImage,
//     },
//   ];

//   return (
//     <Helmet 
//       title={pageTitle}
//       titleTemplate={`%s | ${defaultSEO.siteName}`}
//       link={[
//         {
//           rel: 'canonical',
//           href: canonicalUrl,
//         },
//       ]}
//       meta={meta}
//     >
//       <html lang="en" />
//       {noindex && <meta name="robots" content="noindex,nofollow" />}
      
//       {/* Structured Data */}
//       {structuredData && (
//         <script type="application/ld+json">
//           {JSON.stringify(structuredData)}
//         </script>
//       )}
      
//       {children}
//     </Helmet>
//   );
// };

// SEO.propTypes = {
//   title: PropTypes.string,
//   description: PropTypes.string,

//   image: PropTypes.string,
//   url: PropTypes.string,
//   ogType: PropTypes.string,
//   structuredData: PropTypes.oneOfType([
//     PropTypes.object,
//     PropTypes.array
//   ]),
//   noindex: PropTypes.bool,
//   children: PropTypes.node,
// };

// export default SEO;

// src/components/SEO/index.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { defaultSEO } from './DefaultSEO';

const SEO = ({ 
  title,
  description,
  image,
  url,
  ogType = 'website',
  structuredData = null,
  noindex = false,
  children
}) => {
  const siteUrl = process.env.REACT_APP_SITE_URL || 'https://newtonxr.com';
  const pageTitle = title || defaultSEO.title;
  const metaDescription = description || defaultSEO.description;
  const metaImage = image || defaultSEO.image;
  const canonicalUrl = `${siteUrl}${url || ''}`;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <link rel="canonical" href={canonicalUrl} />
      <meta name="description" content={metaDescription} />
      
      {/* Open Graph */}
      <meta property="og:site_name" content={defaultSEO.siteName} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content={ogType} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:locale" content={defaultSEO.language} />
      
      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={defaultSEO.twitterHandle} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
      
      {/* Language and Robots */}
      <html lang="en" />
      <meta name="robots" content={noindex ? "noindex,nofollow" : "index,follow,max-image-preview:large"} />
      
      {/* Structured Data */}
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
      
      {children}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  url: PropTypes.string.isRequired,
  ogType: PropTypes.string,
  structuredData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  noindex: PropTypes.bool,
  children: PropTypes.node,
};

SEO.defaultProps = {
  ogType: 'website',
  noindex: false,
  image: defaultSEO.image,
  structuredData: null,
};

export default SEO;