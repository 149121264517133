// src/pages/ContactUs/index.js
import React, { useEffect } from 'react';
import SEO from '../../components/SEO';
import { SchemaBuilder } from '../../components/SEO/SchemaBuilder';
import { SEOUtils } from '../../components/SEO/utils';
import ContactHeader from './ContactHeader';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Create breadcrumb data
  const breadcrumbs = SEOUtils.generateBreadcrumbs('/contact-us');
  const breadcrumbSchema = SchemaBuilder.getBreadcrumbSchema(breadcrumbs);

  // Create contact page schema
  const contactPageSchema = {
    '@context': 'https://schema.org',
    '@type': 'ContactPage',
    'name': 'Contact Newton Technologies',
    'description': 'Get in touch with Newton Technologies for inquiries about our VR exoskeleton technology.',
    'mainEntity': {
      '@type': 'Organization',
      'name': 'Newton Technologies',
      'description': 'Revolutionary VR exoskeleton technology company',
      'url': 'https://newtonxr.com',
      'contactPoint': [{
        '@type': 'ContactPoint',
        'telephone': '+972 559572906',
        'contactType': 'sales',
        'email': 'info@newtonxr.com',
        'areaServed': 'Worldwide',
        'availableLanguage': ['English', 'Hebrew'],
        'hoursAvailable': 'Mo-Fr 09:00-18:00'
      }],
      'address': {
        '@type': 'PostalAddress',
        'addressLocality': 'Tel Aviv',
        'addressCountry': 'Israel'
      }
    }
  };

  // Local business schema for physical location
  const localBusinessSchema = {
    '@context': 'https://schema.org',
    '@type': 'TechnologyCompany',
    'name': 'Newton Technologies',
    'description': 'Innovators in VR exoskeleton technology',
    'address': {
      '@type': 'PostalAddress',
      'addressLocality': 'Tel Aviv',
      'addressCountry': 'Israel'
    },
    'geo': {
      '@type': 'GeoCoordinates',
      'latitude': '32.0853',
      'longitude': '34.7818'
    },
    'openingHours': 'Mo-Fr 09:00-18:00',
    'telephone': '+972 559572906',
    'email': 'info@newtonxr.com',
    'areaServed': {
      '@type': 'GeoCircle',
      'geoMidpoint': {
        '@type': 'GeoCoordinates',
        'latitude': '32.0853',
        'longitude': '34.7818'
      },
      'geoRadius': '50000'
    }
  };

  return (
    <div className="container mx-auto px-6 py-24 max-w-7xl">
      <SEO 
        title="Contact Us - Newton Technologies"
        description="Get in touch with Newton Technologies to learn more about our revolutionary VR exoskeleton technology. Contact our team for inquiries, partnerships, or demonstrations."
        keywords="contact Newton Technologies, VR technology contact, ExoSuit VR inquiry, virtual reality consultation, Newton tech support"
        image="/images/contact-hero.jpg"
        url="/contact-us"
        ogType="website"
        structuredData={[
          contactPageSchema,
          localBusinessSchema,
          breadcrumbSchema
        ]}
      >
        {/* Additional meta tags for contact page */}
        <meta name="contact:email" content="info@newtonxr.com" />
        <meta name="contact:phone" content="+972 559572906" />
        <meta name="contact:location" content="Tel Aviv, Israel" />
        <meta name="twitter:label1" content="Response Time" />
        <meta name="twitter:data1" content="Within 24 hours" />
      </SEO>
      
      <ContactHeader />
      
      <div className="grid md:grid-cols-2 gap-12">
        <ContactForm />
        <ContactInfo />
      </div>
    </div>
  );
};

export default ContactUs;