// src/components/Layout.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { SchemaBuilder } from '../components/SEO/SchemaBuilder';
import { defaultSEO } from '../components/SEO/DefaultSEO';

function Layout({ children }) {
  const location = useLocation();

  // Generate organization schema
  const organizationSchema = SchemaBuilder.getOrganizationSchema();

  // Generate website schema
  const websiteSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    'name': defaultSEO.siteName,
    'url': defaultSEO.organization.url,
    'potentialAction': {
      '@type': 'SearchAction',
      'target': {
        '@type': 'EntryPoint',
        'urlTemplate': `${defaultSEO.organization.url}/search?q={search_term_string}`
      },
      'query-input': 'required name=search_term_string'
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1d2027] via-[#2a3540] to-[#3c4e5c] text-[#f2f2f2] font-avenir">
      <Helmet>
        {/* Default meta tags */}
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        
        {/* PWA meta tags */}
        <meta name="theme-color" content="#1d2027" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={defaultSEO.siteName} />
        
        {/* Default SEO tags */}
        <meta name="author" content={defaultSEO.organization.name} />
        <meta name="publisher" content={defaultSEO.organization.name} />
        <meta name="robots" content="index, follow" />
        
        {/* Prevent phone number detection on iOS */}
        <meta name="format-detection" content="telephone=no" />
        
        {/* Favicon and app icons */}
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/logo192.png" />
        
        {/* Default OpenGraph tags */}
        <meta property="og:site_name" content={defaultSEO.siteName} />
        <meta property="og:locale" content={defaultSEO.language} />
        
        {/* Twitter card defaults */}
        <meta name="twitter:card" content="summary_large_image" />
        {defaultSEO.twitterHandle && (
          <meta name="twitter:site" content={defaultSEO.twitterHandle} />
        )}
        
        {/* Preconnect to important third-party origins */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(websiteSchema)}
        </script>
        
        {/* Canonical URL */}
        <link rel="canonical" href={`${defaultSEO.organization.url}${location.pathname}`} />
      </Helmet>

      <Header />
      <main className="overflow-hidden">
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;