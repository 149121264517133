// src/pages/404/index.js
import React from 'react';
import SEO from '../../components/SEO';
import NotFound from './NotFound';

const NotFoundPage = () => {
  // Custom schema for error page
  const errorSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    'name': '404 - Page Not Found',
    'description': 'The requested page could not be found on Newton Technologies website.',
    'isPartOf': {
      '@type': 'WebSite',
      'name': 'Newton Technologies',
      'url': 'https://newtonxr.com'
    }
  };

  return (
    <>
      <SEO 
        title="404 - Page Not Found"
        description="The page you're looking for cannot be found. Explore Newton Technologies' VR exoskeleton technology through our other pages."
        url="/404"
        noindex={true}
        structuredData={errorSchema}
      >
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </SEO>
      <NotFound />
    </>
  );
};

export default NotFoundPage;