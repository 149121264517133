// // src/pages/vision/index.js
// import React, { useEffect } from 'react';
// import SEO from '../../components/SEO';
// import { SchemaBuilder } from '../../components/SEO/SchemaBuilder';
// import { SEOUtils } from '../../components/SEO/utils';
// import VisionHeader from './VisionHeader';
// import VisionMissionSection from './VisionMissionSection';
// import CoreValuesSection from './CoreValuesSection';
// import Separator from './Separator';
// import FutureImpactSection from './FutureImpactSection';

// const Vision = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   // Create breadcrumb data
//   const breadcrumbs = SEOUtils.generateBreadcrumbs('/vision');
//   const breadcrumbSchema = SchemaBuilder.getBreadcrumbSchema(breadcrumbs);

//   // Vision page schema
//   const visionPageSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'AboutPage',
//     'mainEntity': {
//       '@type': 'Organization',
//       'name': 'Newton Technologies',
//       'description': 'Newton Technologies is revolutionizing virtual reality interaction through groundbreaking force feedback technology.',
//       'foundingDate': '2023',
//       'foundingLocation': 'Tel Aviv, Israel',
//       'knowsAbout': [
//         'Virtual Reality Technology',
//         'Force Feedback Systems',
//         'VR Exoskeletons',
//         'Haptic Technology'
//       ]
//     }
//   };

//   // Create mission statement schema
//   const missionSchema = {
//     '@context': 'https://schema.org',
//     '@type': 'Organization',
//     'name': 'Newton Technologies',
//     'slogan': 'Redefining the Future of VR Interaction',
//     'mission': "Newton's mission is to revolutionize human interaction with virtual environments through pioneering force feedback technology.",
//     'ethicsPolicy': [
//       'Innovation and Excellence',
//       'User-Centric Design',
//       'Sustainable Development',
//       'Global Impact'
//     ]
//   };

//   return (
//     <div className="container mx-auto px-6 py-24 max-w-7xl">
//       <SEO 
//         title="Our Vision - Redefining the Future of VR Interaction"
//         description="Discover Newton Technologies' vision for revolutionizing virtual reality. We're bridging physical and digital worlds through innovative force feedback technology and transformative VR solutions."
//         keywords="Newton Technologies vision, VR innovation, future of virtual reality, VR technology vision, force feedback future, VR industry transformation"
//         image="/images/vision-hero.jpg"
//         url="/vision"
//         ogType="website"
//         structuredData={[
//           visionPageSchema,
//           missionSchema,
//           breadcrumbSchema
//         ]}
//       >
//         {/* Additional meta tags for vision page */}
//         <meta name="twitter:label1" content="Founded" />
//         <meta name="twitter:data1" content="2023" />
//         <meta name="twitter:label2" content="Location" />
//         <meta name="twitter:data2" content="Tel Aviv, Israel" />
//       </SEO>

//       <VisionHeader />
//       <VisionMissionSection />
//       <CoreValuesSection />
//       <Separator />
//       <FutureImpactSection />
//     </div>
//   );
// };

// export default Vision;


// src/pages/vision/index.js
import React, { useEffect } from 'react';
import SEO from '../../components/SEO';
import { SchemaBuilder } from '../../components/SEO/SchemaBuilder';
import { SEOUtils } from '../../components/SEO/utils';
import VisionHeader from './VisionHeader';
import VisionMissionSection from './VisionMissionSection';
import CoreValuesSection from './CoreValuesSection';
import Separator from './Separator';
import FutureImpactSection from './FutureImpactSection';

const Vision = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const breadcrumbs = SEOUtils.generateBreadcrumbs('/vision');
  const breadcrumbSchema = SchemaBuilder.getBreadcrumbSchema(breadcrumbs);

  const visionSchema = {
    '@context': 'https://schema.org',
    '@type': 'AboutPage',
    mainEntity: {
      '@type': 'Organization',
      name: 'Newton Technologies',
      description: 'Pioneering force feedback technology to revolutionize virtual reality interaction.',
      foundingDate: '2023',
      foundingLocation: {
        '@type': 'Place',
        name: 'Tel Aviv, Israel'
      },
      knowsAbout: [
        'Virtual Reality Technology',
        'Force Feedback Systems',
        'VR Exoskeletons',
        'Haptic Technology'
      ],
      slogan: 'Redefining the Future of VR Interaction',
      mission: 'To revolutionize human interaction with virtual environments through pioneering force feedback technology.',
      hasOfferCatalog: {
        '@type': 'OfferCatalog',
        name: 'VR Solutions',
        itemListElement: [
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Product',
              name: 'ExoSuit VR',
              description: 'Advanced force feedback exoskeleton for virtual reality'
            }
          }
        ]
      }
    }
  };

  const corporateSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Newton Technologies',
    ethicsPolicy: [
      'Innovation and Excellence',
      'User-Centric Design',
      'Sustainable Development',
      'Global Impact'
    ],
    awards: [{
      '@type': 'Award',
      name: 'Patent Grant',
      description: 'VR Exoskeleton Technology Patent (2022)'
    }],
    actionableFeedback: {
      '@type': 'CreativeWork',
      name: 'Core Values',
      text: [
        'Innovation First',
        'User Experience Focus',
        'Collaborative Excellence',
        'Sustainable Development'
      ]
    }
  };

  return (
    <div className="container mx-auto px-6 py-24 max-w-7xl">
      <SEO 
        title="Newton Technologies Vision | Future of VR Interaction"
        description="Newton's vision: bridging physical and digital realities through revolutionary force feedback technology. Transforming how humans experience virtual worlds."
        url="/vision"
        ogType="website"
        image="/images/vision-hero.jpg"
        structuredData={[
          visionSchema,
          corporateSchema,
          breadcrumbSchema
        ]}
      >
        {/* Company Vision */}
        <meta property="article:section" content="Company Vision" />
        <meta property="article:tag" content="VR Innovation" />
        <meta property="article:tag" content="Future Technology" />
        <meta property="article:tag" content="Digital Transformation" />
        
        {/* Company Information */}
        <meta property="business:contact_data:locality" content="Tel Aviv" />
        <meta property="business:contact_data:country_name" content="Israel" />
        <meta property="business:contact_data:founding_date" content="2023" />
        
        {/* Social Tags */}
        <meta name="twitter:label1" content="Vision" />
        <meta name="twitter:data1" content="Future of VR Interaction" />
        <meta name="twitter:label2" content="Innovation" />
        <meta name="twitter:data2" content="Force Feedback Technology" />
      </SEO>

      <VisionHeader />
      <VisionMissionSection />
      <CoreValuesSection />
      <Separator />
      <FutureImpactSection />
    </div>
  );
};

export default Vision;